import styled from 'styled-components';

import { MediaQueries, Colors } from 'environment';
import { FluidImage } from 'components/ui';
import {
    DESKTOP_GRID_SIZE,
    DESKTOP_GRID_COLUMN,
    DESKTOP_GRID_SPACING,
    TABLET_GRID_SIZE
} from 'consts';

export const CTAContent = styled.div`
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 6 + DESKTOP_GRID_SPACING * 5}rem;
    }

    @media ${MediaQueries.phone} {
        padding: 0 2rem;
    }
`;

export const CTATitle = styled.h2`
    color: ${Colors.violet};
    width: 100%;
    text-align: center;

    @media ${MediaQueries.phone} {
        font-size: 3.6rem;
        line-height: 5rem;
        margin-top: -15rem;
    }
`;

export const CTASubTitle = styled.p`
    color: ${Colors.violet};
    margin-top: 2rem;
    text-align: center;

    @media ${MediaQueries.desktop} {
        margin-bottom: 8rem;
    }

    @media ${MediaQueries.tablet} {
        margin-bottom: 8rem;
    }

    @media ${MediaQueries.phone} {
        margin-bottom: 4rem;
    }
`;

export const CTAButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    @media ${MediaQueries.desktop} {
        margin-bottom: 12rem;
    }

    @media ${MediaQueries.tablet} {
        margin-bottom: 12rem;
    }

    @media ${MediaQueries.phone} {
        margin-bottom: 6rem;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        margin-top: 8rem;
        margin-bottom: 4rem;
        color: ${Colors.violet};

        @media ${MediaQueries.phone} {
            font-weight: 700;
        }
    }

    a {
        color: ${Colors.magenta};
    }

    ul {
        list-style-type: none;
    }

    p {
        margin-bottom: 2rem;
    }

    ul:nth-of-type(1) {
        li {
            display: flex;
            align-items: center;
            height: 100%;
            margin-bottom: 1.6rem;
            width: 100%;

            @media ${MediaQueries.tablet} {
                max-width: ${TABLET_GRID_SIZE}rem;
            }

            @media ${MediaQueries.phone} {
                align-items: flex-start;
            }

            ::before {
                content: url(/images/check-green.svg);
                height: 4rem;
                margin-right: 2rem;
            }

            :last-child {
                margin-bottom: 2.6rem;
            }
        }
    }

    ul:nth-of-type(2) {
        li {
            display: flex;
            align-items: center;
            height: 100%;
            margin-bottom: 1.6rem;
            width: 100%;

            @media ${MediaQueries.tablet} {
                max-width: ${TABLET_GRID_SIZE}rem;
            }

            @media ${MediaQueries.phone} {
                align-items: flex-start;
            }

            ::before {
                content: url(/images/check-orange.svg);
                height: 4rem;
                margin-right: 2rem;
            }

            :last-child {
                margin-bottom: 2.6rem;
            }
        }
    }

    ul:nth-of-type(3) {
        li {
            display: flex;
            align-items: center;
            height: 100%;
            margin-bottom: 1.6rem;
            width: 100%;

            @media ${MediaQueries.tablet} {
                max-width: ${TABLET_GRID_SIZE}rem;
            }

            @media ${MediaQueries.phone} {
                align-items: flex-start;
            }

            ::before {
                content: url(/images/check-magenta.svg);
                height: 4rem;
                margin-right: 2rem;
            }

            :last-child {
                margin-bottom: 2.6rem;
            }
        }
    }
`;

export const Content = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 6rem;

    h2 {
        margin-top: 6rem;
    }

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 8 + DESKTOP_GRID_SPACING * 7}rem;
        padding: 0 5rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
        margin-bottom: -3rem;
    }

    @media ${MediaQueries.phone} {
        margin-top: 5rem;
        padding: 0 2rem;

        h2 {
            font-size: 3.6rem;
            line-height: 5rem;
            text-align: center;
            margin-top: 0.8rem;
        }

        p {
            text-align: center;
        }

        a {
            text-align: center;
        }
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;

    @media ${MediaQueries.desktop} {
        padding-bottom: 8rem;
    }

    @media ${MediaQueries.tablet} {
        padding-bottom: 8rem;
    }

    @media ${MediaQueries.phone} {
        padding-bottom: 4rem;
    }
`;

export const HeaderBackgroundImage = styled.img`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: right;

    @media ${MediaQueries.desktop} {
        object-position: top; // a decent option :D
    }
`;

export const HeaderGrid = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;

    @media ${MediaQueries.desktop} {
        justify-content: space-between;
        width: ${DESKTOP_GRID_SIZE}rem;
    }

    @media ${MediaQueries.tablet} {
        flex-direction: column-reverse;
        justify-content: center;
        width: ${TABLET_GRID_SIZE}rem;
    }

    @media ${MediaQueries.phone} {
        flex-direction: column-reverse;
        justify-content: center;
        padding: 0 2rem;
    }
`;

export const Summary = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;

    @media ${MediaQueries.desktop} {
        height: 100%;
        padding-top: 24rem;
        text-align: unset;
        align-items: flex-start;
    }

    @media ${MediaQueries.phoneAndTablet} {
        justify-content: flex-start;
        align-items: center;
    }

    h1 {
        color: ${Colors.white};
        width: 100%;

        @media ${MediaQueries.desktop} {
            max-width: ${DESKTOP_GRID_COLUMN * 6 + DESKTOP_GRID_SPACING * 5}rem;
            margin-bottom: 4rem;
        }

        @media ${MediaQueries.tablet} {
            max-width: ${TABLET_GRID_SIZE}rem;
            text-align: center;
            margin-bottom: 4rem;
        }

        @media ${MediaQueries.phone} {
            font-size: 3.6rem;
            line-height: 5rem;
            margin-bottom: 2.4rem;
        }
    }
`;

export const LogoImage = styled(FluidImage)`
    width: 32rem;
    height: 32rem;

    @media ${MediaQueries.desktop} {
        margin-top: 12rem;
    }

    @media ${MediaQueries.tablet} {
        margin-top: 8rem;
    }

    @media ${MediaQueries.phone} {
        margin-top: 8rem;
    }
`;

export const CTAContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
