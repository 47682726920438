import { graphql, navigate } from 'gatsby';
import React, { useState } from 'react';

import { ApplyModal, BackgroundImage, TertiaryButton } from 'components/ui';
import { WINDOW_EXISTS } from 'consts';
import { jobs, positions } from 'data/careers.json';
import seo from 'data/seo.json';
import analytics from 'data/analytics.json';
import { useQueryContext } from 'hooks';
import { ImageSharpData } from 'types';
import { SEO } from 'components/ui';

import {
    CTAButtonContainer,
    CTAContent,
    CTATitle,
    Container,
    Content,
    LogoImage,
    Header,
    HeaderGrid,
    Summary,
    CTAContainer,
    CTASubTitle,
    HeaderBackgroundImage
} from './Job.style';

interface Props {
    data: {
        markdownRemark: {
            frontmatter: {
                excerpt: string;
                featuredImage: ImageSharpData;
                metaImage: ImageSharpData;
                slug: string;
                isAvailable: boolean;
                title: string;
                metaDescription: string;
            };
            html: string;
        };
    };
}

function getImage(index: number) {
    if (index % 4 === 0) {
        return '/images/waves-job-orange.png';
    }
    if (index % 4 === 1) {
        return '/images/waves-job-pink.png';
    }
    if (index % 4 === 2) {
        return '/images/waves-job-turqoise.png';
    }
    return '/images/waves-job-violet.png';
}

export default function Job({ data }: Props) {
    const jobFrontmatter = data.markdownRemark.frontmatter;

    const {
        images: {
            positions: { image }
        }
    } = useQueryContext();

    if (WINDOW_EXISTS && !jobFrontmatter.isAvailable) {
        navigate('/job-unavailable');
    }

    const { jobs: contextJobs } = useQueryContext();
    const availableJobsByName = contextJobs
        .filter((job) => job.isAvailable)
        .map((job) => job.path.split('/').pop());

    let job;

    if (WINDOW_EXISTS) {
        job = jobs.find(
            (e) =>
                e ===
                window.location.pathname
                    .split('/')
                    .filter((el) => el !== '')
                    .pop()
        );
    }

    const ogImage = jobFrontmatter.metaImage
        ? jobFrontmatter.metaImage.childImageSharp.fluid.src
        : 'Job Image';
    const [open, setOpen] = useState(WINDOW_EXISTS && window.location.hash.includes('apply'));

    function handleModalOpen() {
        setOpen(true);
    }

    function handleModalClose() {
        setOpen(false);
    }

    const metaDescription = jobFrontmatter.metaDescription || seo.careersOgDescription;

    return (
        <>
            <Container>
                <SEO
                    title={jobFrontmatter.title}
                    description={metaDescription}
                    ogTitle={jobFrontmatter.title}
                    ogDescription={metaDescription}
                    ogImage={ogImage}
                />
                <Header>
                    <HeaderBackgroundImage
                        src={getImage(availableJobsByName.indexOf(jobFrontmatter.slug))}
                        alt="Header background"
                    />
                    <HeaderGrid>
                        <Summary>
                            <h1>{jobFrontmatter.title}</h1>
                            <TertiaryButton
                                name={analytics.careersJobApplyForThisPosition}
                                onClick={handleModalOpen}
                            >
                                {positions.ctaButtonLabel}
                            </TertiaryButton>
                        </Summary>
                        <LogoImage
                            mobile={jobFrontmatter.featuredImage.childImageSharp}
                            tablet={jobFrontmatter.featuredImage.childImageSharp}
                            desktop={jobFrontmatter.featuredImage.childImageSharp}
                        />
                    </HeaderGrid>
                </Header>
                <Content dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
                <ApplyModal
                    open={open}
                    job={job}
                    onClose={handleModalClose}
                    title={jobFrontmatter.title}
                />
            </Container>
            <CTAContainer>
                <BackgroundImage
                    mobile={image.mobile}
                    tablet={image.tablet}
                    desktop={image.desktop}
                    alt={positions.alt}
                    scaleX={1.05}
                    position="relative"
                />
                <CTAContent>
                    <CTATitle>{positions.ctaTitle}</CTATitle>
                    <CTASubTitle>{positions.ctaSubTitle}</CTASubTitle>
                    <CTAButtonContainer>
                        <TertiaryButton
                            name={analytics.careersJobApplyForThisPosition}
                            onClick={handleModalOpen}
                        >
                            {positions.ctaButtonLabel}
                        </TertiaryButton>
                    </CTAButtonContainer>
                </CTAContent>
            </CTAContainer>
        </>
    );
}

export const pageQuery = graphql`
    query JobBySlug($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            timeToRead
            excerpt
            frontmatter {
                title
                featuredImage {
                    childImageSharp {
                        fluid(quality: 85, maxWidth: 3840) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                metaImage {
                    childImageSharp {
                        fluid(quality: 85, maxWidth: 3840) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                isAvailable
                excerpt
                slug
                metaDescription
            }
        }
    }
`;
